<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-item label="角色名称">
              <a-input placeholder="请输入" v-model="queryParam.name" allowClear/>
            </a-form-item>
          </a-col>
          <!-- <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-item label="状态">
              <a-select placeholder="请选择" v-model="queryParam.flagEnable" default-value="false">
                <a-select-option :value="''">全部</a-select-option>
                <a-select-option :value="false">正常</a-select-option>
                <a-select-option :value="true">禁用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="query()">查询</a-button>
              <a-button style="margin-left: 8px" @click="reset()">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button v-if="addEnable" type="primary" icon="plus" @click="toHandler('add')">新建</a-button>
<!--      <a-dropdown v-if="removeEnable && selectedRowKeys.length > 0">-->
<!--        <a-button type="danger" icon="delete" @click="toHandler('del')">删除</a-button>-->
<!--      </a-dropdown>-->
    </div>
    <a-table
      size="default"
      ref="table"
      rowKey="id"
      :rowSelection="{ type: 'radio', selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :columns="columns"
      :data-source="tableData"
    >
      <span slot="flagEnable" slot-scope="text, record">
        <a-switch v-model="record.flagEnable" v-if="record.name !== '超级管理员'" @change="onChangeStatus(record)"/>
        <a-switch v-model="record.flagEnable" v-else disabled="true"/>
      </span>
      <span slot="action" slot-scope="text, record">
        <div v-if="!record.flagAllPermission && record.name !== '超级管理员'">
          <a v-if="editEnabel" @click="toHandler('edit', record)">编辑</a>
          <a-divider type="vertical"/>
          <a v-if="editEnabel" @click="handleScope(record)">数据权限</a>
          <a-divider type="vertical"/>
          <a v-if="removeEnable" @click="toHandler('del', record.id)">删除</a>
        </div>
      </span>
    </a-table>
    <role-modal ref="modal" @reload="getList()"/>
    <role-scope-modal ref="scopemodal" @ok="getList()"/>
  </a-card>
</template>

<script>
import {listFirmRole, delFirmRole} from './api/FirmRoleApi'
import RoleModal from './components/FirmRoleEditModal.vue'
import RoleScopeModal from './components/RoleScopeModal.vue'
import pick from 'lodash.pick'
import {checkPermission} from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}

export default {
  name: 'TableList',
  components: {
    RoleModal,
    RoleScopeModal,
  },
  data() {
    return {
      queryParam: {}, // 查询参数
      columns: [
        {
          title: '角色名称',
          dataIndex: 'name',
        },
        {
          title: '角色说明',
          dataIndex: 'remark',
        },
        {
          title: '是否启用',
          dataIndex: 'flagEnable',
          scopedSlots: {customRender: 'flagEnable'},
        },
        {
          title: '操作',
          width: '200px',
          dataIndex: 'action',
          scopedSlots: {customRender: 'action'},
        },
      ],
      page: Object.assign({}, pageSource),
      tableData: [],
      selectedRowKeys: [],
      selectedRows: [],
      tableLoading: false, //表格loading
      addEnable: checkPermission('firm:role:add'),
      editEnabel: checkPermission('firm:role:edit'),
      removeEnable: checkPermission('firm:role:remove'),
      expandedKeys: [0],
      dataList: [],
      StationId: 0,
    }
  },
  created() {
    this.getList()
    this.getTreeList()
  },
  methods: {
    getList() {
      listFirmRole({
        current: this.page.current,
        pageSize: this.page.pageSize,
        ...this.queryParam,
        sourceType: 1,
      })
        .then((res) => {
          if (res.code === 200) {
            const {total, records} = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },

    // 获取左侧树
    getTreeList() {
      this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
        this.dataList = res.body
      })
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    // 选中筛选
    handleSelect(value) {
      let id = Number(value.toString())
      this.StationId = id
      this.getList()
    },

    // 查询
    query(){
      this.page.current = 1;
      this.getList();
    },

    // 重置
    reset() {
      this.queryParam = {}
      this.page.current = 1;
      this.page.pageSize = 10;
      // this.page = Object.assign({}, pageSource)
      this.getList()
    },
    toHandler(name, row) {
      const _this = this
      if (name === 'add') {
        return _this.$refs.modal.setRowData({}, 'add')
      }
      // if (_this.selectedRows.length === 0) {
      //   _this.$notification.warning({
      //     message: '请选择一条记录',
      //   })
      //   return
      // }
      switch (name) {
        case 'edit':
          _this.$refs.modal.setRowData(row, 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delFirmRole(row)
              if (res.code === 200) {
                _this.$notification.success({message: res.message})
                _this.getList()
              } else {
                _this.$notification.error({message: res.message})
              }
            },
            onCancel() {
            },
          })
          break
      }
    },
    handleAdd(parentId) {
      this.$refs.modal.add(parentId)
    },
    handleEdit(record) {
      this.$refs.modal.edit(record)
    },
    handleScope(record) {
      this.$refs.scopemodal.edit(record)
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleOk() {
      this.$refs.table.refresh(true)
    },
    delByIds(ids) {
      const that = this
      this.$confirm({
        title: '警告',
        content: `真的要删除选中角色吗?`,
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          delRole({ids: ids.join(',')}).then((res) => {
            if (res.code === 200) {
              that.$message.success(res.message)
              that.handleOk()
            } else {
              that.$message.error(res.message)
            }
            // const difference = new Set(this.selectedRowKeys.filter(x => !new Set(ids).has(x)))
            // this.selectedRowKeys = Array.from(difference)
            that.selectedRowKeys = []
          })
        },
        onCancel() {
          // console.log('Cancel')
        },
      })
    },
    onChangeStatus(record) {
      let obj={
        id:record.id
      }
      this.axios.post('/api/base/firm/role/status',obj).then((res) =>{
        if (res.code == 200) {
          this.$message.success(res.message)
          this.getList()
        } else {
          this.getList()
        }
      }).catch(err => {
        this.getList()
      })
    },

  },
}
</script>

<style lang="scss" scoped>
.tree-outer {
  height: 500px;
  overflow-y: auto;
}
</style>
